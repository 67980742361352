import { fetch } from "../../utils";

const HostName = process.env.REACT_APP_API_HOSTNAME;
const token = localStorage.getItem('token')

const headers = {
  "Content-Type": "application/json",
  "access-control-allow-origin": "*",
  'Authorization': 'Bearer ' + token,
}
const formDataDeaders = {
  "access-control-allow-origin": "*",
  'Authorization': 'Bearer ' + token,
}

export const createParkpliantNotice = async (value) => {
  console.log(value)
  return fetch(`${HostName}/parkpliant/create-notice`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};