import React, { useEffect } from 'react'
import CloseNotification from "../../images/ic-close-btn.svg";
import success from "../../images/ic-success.svg";
import warning from "../../images/ic-warning.svg";
import error from "../../images/ic-error.svg";
import info from "../../images/ic-informational.svg";
import { capitalize } from '../../global/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/actioncreator';

const images = {
    success,
    warning,
    error,
    info
}


function Alert() {
    const dispatch = useDispatch();
    const alert = useSelector(state => state?.alert)
    let { message, status } = alert
    console.log(message, status)
    const error_message_time = status == 'error' ? 6000 : 2000;
    if(message === "Failed to fetch") message = "Something went wrong, please try again later!"
    useEffect(() => {
        setTimeout(() => {
            dispatch(setAlert("hide", ""))
        }, error_message_time)
    }, [])
    return (
        <>
                <div className="NotificationButtonWrapper ">
                    <button className="NotificationSuccessful">
                        <div className="Flexsec">
                            <img src={images[status]} className="SuccessIcon" />
                            <h5>{capitalize(status)}!</h5>
                            <span>
                                <img src={CloseNotification}
                                    onClick={
                                        () => dispatch(setAlert("hide", ""))
                                    } />
                            </span>
                        </div>
                        <p>
                            {message}
                        </p>
                    </button>
                </div>
        
        </>
    )
}


export default Alert



// <button className="NotificationWarning">
// <div className="Flexsec">
//     <img src={IcWarning} className="SuccessIcon" />
//     <h5>Warning </h5>
//     <span>
//         <img src={CloseNotification} />
//     </span>
// </div>
// <p>
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry.Lorem Ipsum has been.
// </p>
// </button>

// <button className="NotificationError">
// <div className="Flexsec">
//     <img src={IcError} className="SuccessIcon" />
//     <h5>Error</h5>
//     <span>
//         <img src={CloseNotification} />
//     </span>
// </div>
// <p>
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry.Lorem Ipsum has been.
// </p>
// </button>

// <button className="NotificationInformational">
// <div className="Flexsec">
//     <img src={IcInformational} className="SuccessIcon" />
//     <h5>Informational</h5>
//     <span>
//         <img src={CloseNotification} />
//     </span>
// </div>
// <p>
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry.Lorem Ipsum has been.
// </p>
// </button>