
export const setLoading = (loading) => {
    return (dispatch) => {
        dispatch({
            type: 'setLoading',
            payload: loading
        })
    }
}


export const setAlert = (status, message) => {
    return (dispatch) => {
        dispatch({
            type: 'setAlert',
            payload: { message, status }
        })
    }
}
