import fetch from "isomorphic-fetch";
// import Cookies from 'universal-cookie'

// import Config from '../config'

// const cookies = new Cookies()

import axios from "axios";
import get from "lodash/get";

const handleHTTPErrors = (res) => {
  if (res.ok) return res;
  return res.json().then((err) => {
    throw err;
  });
};

export default function fetchUrl(url, options) {
  const jwtToken = localStorage.getItem("token");
  // const jwtToken = cookies.get('Authorization');
  if (jwtToken) {
    let authAddedOptions = options;
    if (typeof options !== "object") {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== "object") {
      authAddedOptions.headers = {};
    }
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: "Bearer " + jwtToken,
      // Authorization:
      //   "Bearer " +
      //   "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAidW5pcXVlX25hbWUiOiAic2phaW4iLA0KICAiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9BcHBVc2VySWQiOiAiMTE3OTUiLA0KICAiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9GZWRlcmF0ZWRVc2VySWQiOiAiYjk2NzkzMDgtM2IyNC00M2JkLWJhZmYtNWRjMGZiNDUxMGU1IiwNCiAgImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvTmlja05hbWUiOiAiYjk2NzkzMDgtM2IyNC00M2JkLWJhZmYtNWRjMGZiNDUxMGU1IiwNCiAgIm5iZiI6IDE2NjUxMTkyNTIsDQogICJleHAiOiAxNjY3NzExMjUyLA0KICAiaWF0IjogMTY2NTExOTI1MiwNCiAgImlzcyI6ICJpc3MiDQp9.iqai9KxmQ53v3QNXhRFYYGupBycOh8qD2l5b6eT6Xj4",
    };
    // return request(authAddedOptions)
    return fetch(url, authAddedOptions).then(handleHTTPErrors);
  } else {
    return fetch(url, options).then(handleHTTPErrors);
  }
}
